/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { Column, Row, usePagination, useSortBy, useTable } from 'react-table';
import { SortIcon } from '../../assets/images';
import CONSTANTS from '../../constants';
import { IPaginationData, ISort, IStringObject } from '../../interfaces';
import { IReports } from '../../interfaces/reports';
import { DateTimeColumn, NoDocumentsFound, StatusColumn } from '../General';
import { SkeletonText } from '../Skeleton';
import Pagination from '../pagination/Pagination';
import './ReportsTablePage.scss';

const DocNumberDocument = (row: Row<IReports>) => {
    const history = useHistory();
    const {
        original: { documentNumber, documentId },
    } = row;

    const onClick = () => {
        history.push({
            pathname: `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
            search: `?doc_id=${documentId}`,
        });
    };

    // Define the URL for the link
    const documentDetailsURL = `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}?doc_id=${documentId}`;

    return (
        // Use an anchor tag for the link
        <a href={documentDetailsURL} className="link-style" onClick={onClick} onKeyPress={onClick}>
            <SkeletonText text={documentNumber} width={200} />
        </a>
    );
};

const ApprovalDaysColumn = ({ value }: { value: number | null }): JSX.Element => (
    <>{value === -1 ? <Skeleton width={150} /> : value}</>
);

const columnMapping: IStringObject = {
    createdAt: 'createdAt',
    submittedOn: 'submittedOn',
    ChangeAdministratorDecisionTime: 'ChangeAdministratorDecisionTime',
    publishedOn: 'publishedOn',
    approverDecisionTime: 'approverDecisionTime',
};

const DEFAULT_SORT: ISort = {
    id: 'createdAt',
    desc: true,
};

const COLUMNS: Column<IReports>[] = [
    {
        Header: 'DCO Number',
        accessor: 'documentNumber',
        Cell: ({ row }) => <DocNumberDocument {...row} />,
        minWidth: 300,
        disableSortBy: true,
    },
    {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 300,
        disableSortBy: true,
    },
    {
        Header: 'Creator',
        accessor: 'creator',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 250,
        disableSortBy: true,
    },
    {
        Header: 'Change Administrator',
        accessor: 'ChangeAdministrator',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 250,
        disableSortBy: true,
    },
    {
        Header: 'DCO Creation Date & Time',
        accessor: 'createdAt',
        Cell: ({ value }) => <DateTimeColumn value={value} />,
        minWidth: 300,
        disableSortBy: false,
    },
    {
        Header: 'Lifecycle Status',
        accessor: 'documentState',
        Cell: ({ value }) => <StatusColumn value={value} />,
        minWidth: 150,
        disableSortBy: true,
    },
    {
        Header: 'Submit Count',
        accessor: 'submitCount',
        Cell: ({ value }) => <ApprovalDaysColumn value={value} />,
        minWidth: 150,
        disableSortBy: true,
    },
    {
        Header: 'Latest Submit for Routing Date & Time',
        accessor: 'submittedOn',
        Cell: ({ value }) => <DateTimeColumn value={value} />,
        minWidth: 300,
        disableSortBy: false,
    },
    {
        Header: 'Latest Submit for Approval Date & Time',
        accessor: 'ChangeAdministratorDecisionTime',
        Cell: ({ value }) => <DateTimeColumn value={value} />,
        minWidth: 300,
        disableSortBy: false,
    },
    {
        Header: 'Admin Response Time in Hours',
        accessor: 'AdminResponseTimeHours',
        Cell: ({ value }) => <ApprovalDaysColumn value={value} />,
        minWidth: 250,
        disableSortBy: true,
    },
    {
        Header: 'Approver',
        accessor: 'approverName',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 150,
        disableSortBy: true,
    },
    {
        Header: 'Decision',
        accessor: 'approverState',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 150,
        disableSortBy: true,
    },
    {
        Header: 'Decision Date',
        accessor: 'approverDecisionTime',
        Cell: ({ value }) => <DateTimeColumn value={value} />,
        minWidth: 200,
        disableSortBy: false,
    },
    {
        Header: 'Total Days for Each approver',
        accessor: 'approverResponseTimeDays',
        Cell: ({ value }) => <ApprovalDaysColumn value={value} />,
        minWidth: 200,
        disableSortBy: true,
    },
    {
        Header: 'Change Approval Date',
        accessor: 'publishedOn',
        Cell: ({ value }) => <DateTimeColumn value={value} />,
        minWidth: 200,
        disableSortBy: false,
    },
    {
        Header: 'Total Days for Approval',
        accessor: 'approvalDurationDays',
        Cell: ({ value }) => <ApprovalDaysColumn value={value} />,
        minWidth: 200,
        disableSortBy: true,
    },
];

interface Props {
    data: IReports[];
    pagination: IPaginationData;
    fetchDocuments: (
        pageIndex: number,
        pageSize: number,
        order: string,
        orderType: string | undefined,
    ) => void;
}

const ReportsTablePage: React.FC<Props> = ({ data, pagination, fetchDocuments }) => {
    const columns = useMemo(() => COLUMNS, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize, sortBy },
        canPreviousPage,
        canNextPage,
    } = useTable<IReports>(
        {
            columns,
            data,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
                sortBy: [{ id: DEFAULT_SORT.id, desc: DEFAULT_SORT.desc }],
            },
        },
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        fetchDocuments(
            pageIndex,
            pageSize,
            columnMapping[sortBy[0].id] ? columnMapping[sortBy[0].id] : 'createdAt',
            sortBy[0].desc ? 'DESC' : 'ASC',
        );
    }, [pageIndex, pageSize, sortBy]);

    return (
        <div className="reports-page-table">
            <div className="table-section">
                {page.length > 0 && (
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps(),
                                            )}
                                            {...column.getHeaderProps({
                                                style: {
                                                    minWidth: column.minWidth
                                                        ? column.minWidth
                                                        : 'auto',
                                                },
                                            })}
                                        >
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted && (
                                                    <SortIcon
                                                        className={`sort ${
                                                            column.isSortedDesc ? 'desc' : 'asc'
                                                        } `}
                                                    />
                                                )}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td
                                                className={cell.column.id}
                                                {...cell.getCellProps({
                                                    style: {
                                                        minWidth: cell.column.minWidth
                                                            ? cell.column.minWidth
                                                            : 'auto',
                                                    },
                                                })}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
                {page.length === 0 && <NoDocumentsFound heading="No Data Found" />}
            </div>
            {page.length > 0 && pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                <Pagination
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                />
            )}
        </div>
    );
};

export default ReportsTablePage;