import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import './ndaForm.scss';
import CONSTANTS from '../../constants';
import { userSearch } from '../../API/users';
import { IUserOptionValue } from '../../interfaces';
import { createNDA } from '../../API/document';
import Button from '../../components/button/Button';

// eslint-disable-next-line max-lines-per-function
const NDAForm: React.FC = () => {
    const { user } = useAuthDataContext();
    const [documentData, setDocumentData] = useState({
        documentTypeId: 84,
        effectiveFrom: new Date(),
        data: {
            contractType: ['NDA'],
            partyName: '',
            enphaseEntity: ['Enphase Energy, Inc.'],
            businessAddress: ''
        },
        externalApprovers: [],
        approver: [],
    });
    const [selectedUser, setSelectedUser] = useState<IUserOptionValue[]>();
    const [externalSigner, setExternalSigner] = useState({ name: '', email: '', state: 'NEW' });
    const [isLoading, setLoading] = useState(false);
    const [docId, setDocId] = useState(0);
    const [isApiLoading, setIsApiLoading] = useState(false);

    const promiseOptions = async (inputValue: string): Promise<IUserOptionValue[]> => {
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await userSearch(inputValue);
                if (users?.apiStatus === 'SUCCESS') {
                    const userList = users.users;
                    resolve(userList);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    const submitHandler = async () => {
        const res = await createNDA(
            selectedUser,
            externalSigner,
            documentData,
        );
        if (res?.apiStatus === 'SUCCESS'){
            setDocId(res.docId);
            toast.success('NDA created successfully');
        } else {
            setDocId(0);
            toast.error('Something went wrong');
        }
        setIsApiLoading(false);
    };

    return (
        <>
            <div className="nda-form">
                {!docId ? (
                    <form
                        className='NDA-Form'
                        onSubmit={(event) => {
                            event.preventDefault();
                            submitHandler();
                        }}
                    >
                        <h1 className="header1">Enphase Mutual NDA Request</h1>

                        <h3 className="header3">
                            When you have completed the form below hit Submit and the NDA will be sent to you via email.
                        </h3>
                        <h4 className="header4">
                            Hi, {user.name}. When you submit this form, the owner will see your name and email address.
                        </h4>
                        <div className="form-group">
                            <label className="label-modal">
                                1. What is the desired Effective Date of the NDA?
                            </label>
                            <ReactDatePicker
                                className='form-field'
                                selected={documentData.effectiveFrom}
                                disabled={false}
                                onChange={(date: Date) => {
                                    setDocumentData({ ...documentData, effectiveFrom: date });
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <label className="label-modal">
                                2. What is the business legal name? (Example: ABC, Inc.)
                            </label>
                            <input
                                className='form-field'
                                name=''
                                disabled={false}
                                value={documentData.data.partyName}
                                onChange={(e) => {
                                    setDocumentData({
                                        ...documentData,
                                        data: {
                                            ...documentData.data,
                                            partyName: e.target.value,
                                        },
                                    });
                                }}
                                autoComplete="off"
                            />
                        </div>

                    <div className="form-group">
                        <label className="label-modal">
                            3. What is the primary business address? (Enphase will not accept PO boxes)
                        </label>
                        <input
                            className='form-field'
                            name=''
                            disabled={false}
                            value={documentData.data.businessAddress}
                            onChange={(e) => {
                                setDocumentData({
                                    ...documentData,
                                    data: {
                                        ...documentData.data,
                                        businessAddress: e.target.value,
                                    },
                                });
                            }}
                            autoComplete="off"
                        />
                    </div>

                        <div className="form-group">
                            <label className="label-modal">
                                4. Other Party Contact Info for Signature: Name
                            </label>
                            <input
                                className='form-field'
                                name=''
                                disabled={false}
                                value={externalSigner.name}
                                onChange={(e) =>
                                    setExternalSigner({ ...externalSigner, name: e.target.value })
                                }
                                autoComplete="off"
                            />
                        </div>

                        <div className="form-group">
                            <label className="label-modal">
                                5. Other Party contact for Signature: Email
                            </label>
                            <input
                                className='form-field'
                                name=''
                                type="email"
                                disabled={false}
                                value={externalSigner.email}
                                onChange={(e) =>
                                    setExternalSigner({ ...externalSigner, email: e.target.value })
                                }
                                autoComplete="off"
                            />
                        </div>

                        <div className="form-group">
                            <label className="label-modal">
                                5. Confirm Enphase Signer(VP of your business unit)
                            </label>
                            <AsyncSelect
                                name="user-search"
                                id="user-search"
                                isLoading={isLoading}
                                isMulti
                                placeholder="Search user..."
                                className={`react-select user-search ${''}`}
                                classNamePrefix="select"
                                loadOptions={promiseOptions}
                                getOptionLabel={(e) => `${e.label} | ${e.jobTitle}`}
                                onChange={(option) => {
                                    setSelectedUser(option as IUserOptionValue[]);
                                }}
                            />
                        </div>

                        <Button
                            type="submit"
                            isLoading={isApiLoading}
                            disabled={false}
                            className=''
                            onClick={() => setIsApiLoading(true)}
                        >Submit</Button>
                    </form>
                ) : (
                    <div>
                        Thank you for submitting the NDA form. <a href={`${process.env.REACT_APP_HOST}/app/document-details?doc_id=${docId}`}>Click here</a> to view the submitted NDA.
                    </div>
                )}
            </div>
        </>
    );
};

export default NDAForm;
