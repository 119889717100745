import React from 'react'
import { IField } from '../../../interfaces/document'
import { IDocumentAction } from '../../../reducers/document-reducer'
import Toggle from '../../toggle/Toggle';
import { useDocumentContext } from '../../../contexts/document-provider';

interface Props {
    field: IField;
    isDisabled: boolean;
    value: boolean;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

const ToggleInput: React.FC<Props> = ({
    documentDispatch,
    field,
    isDisabled,
    value,
    error,
}: Props) => {
    // console.log(field.name);
    const {isTravelAuthorizationForm, setIsItACustomaerMeeting} = useDocumentContext();
    return (
        <div className={`${error ? 'error' : ''}`}>
            <Toggle
                isDisabled={isDisabled}
                isChecked={value}
                onclick={() => {
                    if(documentDispatch){
                        documentDispatch({
                            type: 'toggle',
                            inputName: field.name,
                            value: !value,
                        })
                    }
                    if(isTravelAuthorizationForm && field.name === 'customerMetting'){
                        setIsItACustomaerMeeting(!value)
                    }
                }}
            />
        </div>
    )
};

export default ToggleInput
