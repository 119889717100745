const CONSTANTS = {
    BRAND_COLOR: '#E98C00',
    AUTHORIZED_ROLE: 'DMS Admin',
    CLM_AUTHORIZED_ROLE: 'CLM Admin',
    DCO_AUTHORIZED_ROLE: 'DCO Admin',
    USER_SEARCH_LENGTH: 3,
    docTypesColors: { MEMO: '#EDA460', SPEC: '#532029' },
    LOADING: {
        TEXT: 'LOADING',
        NUMBER: 0,
        DATE: new Date(1),
        NUMBER_WITH_0: -1,
    },
    NEW_DOC_STEP: {
        STEP1: 'Document Details',
        STEP2: 'Attachments',
        STEP3: 'Approvals',
    },
    NEW_CONTRACT_STEP: {
        STEP1: 'Document Details',
        STEP2: 'Attachments',
        STEP3: 'ROUTE FOR STAMP AND SIGNATURE',
    },
    NEW_DCO_STEP: {
        STEP1: 'Affected Documents',
        STEP2: 'Attachments',
        STEP3: 'Approvals',
    },
    NEW_DOC_STEP_WITH_NO_ATTACHMENTS: {
        STEP1: 'Document Details',
        STEP3: 'Approvals',
    },
    NEW_DOC_STEP_WITH_DCO_ENABLED_AND_ATTACHMENTS: {
        STEP1: 'Document Details',
        STEP2: 'Attachments',
    },
    NEW_DOC_STEP_WITH_DCO_ENABLED_AND_WITHOUT_ATTACHMENTS: {
        STEP1: 'Document Details',
    },
    DEFAULT_VALUE: {
        'string array': [],
        string: '',
        integer: 0,
        'date-time': new Date(),
        boolean: false,
        document: [],
        'float': 0.0,
    },
    DOCUMENT_STATUS: {
        NEW: 'New',
        DRAFT: 'Draft',
        UNDER_REVIEW: 'Under Review',
        REJECTED: 'Rejected',
        DISCARDED: 'Discarded',
        OBSOLETE: 'Obsolete',
        OBSOLETED: 'Obsoleted',
        REVISED: 'Revised',
        APPROVED: 'Approved',
        ALL: 'All',
        PUBLISHED: 'Published',
        COMPLETED: 'Completed',
        REPUBLISHED: 'Republished',
        SUNSET: 'Sunset',
        PENDING: 'Pending',
        INACTIVE: 'Inactive',
    },
    EFFECTIVITY: {
        ALL: 'All',
        IN_SUNSET: 'In Sunset',
        SUNSET30: '30 Days After Sunset',
        SUNSET60: '60 Days After Sunset',
    },
    DATE_RANGE_DATA: {
        ALL: 'All',
        PAST_24: 'Past 24 hours',
        PAST_WEEK: 'Past week',
        PAST_MONTH: 'Past month',
        PAST_YEAR: 'Past year',
        CUSTOM: 'Custom Range..',
    },
    RANDOM_COMMENT_STRINGS: {
        APPROVE: 'QvfaSimX',
        REJECT: 'GTIuDIkD',
        WITHDRAW: '',
        REVISE: '',
        REVERT: '',
        DISCARD: '',
        '': '',
        AD_HOC: '',
        FORWARD: '',
        REVIEWED: '',
        CLAIM: '',
        UNCLAIM: '',
        REPUBLISH: '',
        TERMINATE: '',
        OBSOLETE: '',
        EDIT: ''
    },
    BANNER: {
        showBanner: `${process.env.REACT_APP_SHOW_BANNER}`,
        BANNER_TEXT: `${process.env.REACT_APP_BANNER_MESSAGE}`,
    },
    RELATIVE_PATHS: {
        dashboard: {
            WPUrl: `${process.env.PUBLIC_URL}/dashboard`,
            Url: `dashboard`,
        },
        createDocument: {
            WPUrl: `${process.env.PUBLIC_URL}/create-document`,
            Url: `create-document`,
        },
        documentTypes: {
            WPUrl: `${process.env.PUBLIC_URL}/document-types`,
            Url: `document-types`,
        },
        documentTypeCreate: {
            WPUrl: `${process.env.PUBLIC_URL}/document-type/create`,
            Url: `document-type/create`,
        },
        documentDetails: {
            WPUrl: `${process.env.PUBLIC_URL}/document-details`,
            Url: `document-details`,
        },
        fileDetails: {
            WPUrl: `${process.env.PUBLIC_URL}/file-details`,
            Url: `file-details`,
        },
        search: {
            WPUrl: `${process.env.PUBLIC_URL}/search`,
            Url: `search`,
        },
        callback: {
            WPUrl: `${process.env.PUBLIC_URL}/oauth/callback`,
            Url: `oauth/callback`,
        },
        settings: {
            WPUrl: `${process.env.PUBLIC_URL}/settings`,
            Url: `settings`,
        },
        taskDetails: {
            WPUrl: `${process.env.PUBLIC_URL}/task-details`,
            Url: `task-details`,
        },
        documentation: {
            WPUrl: `${process.env.PUBLIC_URL}/documentation`,
            Url: `documentation`,
        },
        export: {
            WPUrl: `${process.env.PUBLIC_URL}/document-details/export`,
            Url: `document-details/export`,
        },
        contractsDashboard: {
            WPUrl: `${process.env.PUBLIC_URL}/clm/dashboard`,
            Url: `clm/dashboard`
        },
        contracts: {
            WPUrl: `${process.env.PUBLIC_URL}/clm/contracts`,
            Url: `clm/contracts`,
        },
        adminDocs: {
            WPUrl: `${process.env.PUBLIC_URL}/adm/documents`,
            Url: `adm/documents`,
        },
        adminDocTypes: {
            WPUrl: `${process.env.PUBLIC_URL}/adm/document-types-config`,
            Url: `adm/document-types-config`,
        },
        adminDashboard: {
            WPUrl: `${process.env.PUBLIC_URL}/adm/dashboard`,
            Url: `adm/dashboard`
        },
        adminTasks: {
            WPUrl: `${process.env.PUBLIC_URL}/adm/tasks?prev_page_type=ADMIN_TASKS`,
            Url: `adm/tasks?prev_page_type=ADMIN_TASKS`
        },
        adminDelegations: {
            WPUrl: `${process.env.PUBLIC_URL}/adm/delegations`,
            Url: `/adm/delegations`
        },
        reports: {
            WPUrl: `${process.env.PUBLIC_URL}/reportList`,
            Url: `reportList`,
        },
        report: {
            WPUrl: `${process.env.PUBLIC_URL}/reportList/report`,
            Url: `reportList/report`,
        },
        reportSummary: {
            WPUrl: `${process.env.PUBLIC_URL}/reportList/detail-summary`,
            Url: `reportList/detail-summary`,
        }
    },
    NO_OF_TABLE_ROWS: 10,
    NO_OF_TABLE_ROWS_IN_MODAL: 5,
    ERROR_406: 'Something went wrong',
    ERROR_TASK_NOT_AVAILABLE: "Couldn't find the task!",
};

export default CONSTANTS;
