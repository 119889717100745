import React, { useEffect } from 'react';
import Toggle from '../../components/toggle/Toggle';
import { useDocTypeContext } from '../../contexts/document-type-provider';
import DocTypeNumbering from '../doc-type-numbering/DocTypeNumbering';

import './DocTypeAdditionalConfig.scss';
import { IDocVersionObject } from '../../interfaces/document-type';

// eslint-disable-next-line max-lines-per-function
const DocTypeAdditionalConfig: React.FC = () => {
    const {
        docTypeConfig,
        setDocTypeConfig,
        docTypeInfo,
        generationTrigger,
        setGenerationTrigger,
        docTypeErrors,
        changeMaxRepublishCount,
        changeDefaultApprovalDeadline
    } = useDocTypeContext();

    const versionFieldsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentMinorVersion = docTypeConfig.defaultInitialVersion?.minorVersionNumber;
        const currentMajorVersion = docTypeConfig.defaultInitialVersion?.majorVersionNumber;
        const newVersion = Number(e.target.value);

        const versionObj: IDocVersionObject = {
            majorVersionNumber: e.target.id === 'major' ? newVersion : currentMajorVersion,
            minorVersionNumber: e.target.id === 'minor' ? newVersion : currentMinorVersion,
        };
        setDocTypeConfig({
            ...docTypeConfig,
            defaultInitialVersion: versionObj,
        });
    };
    useEffect(() => {
        if (docTypeConfig.allowDCO)
            setGenerationTrigger("ON_CREATE");
    }, [docTypeConfig.allowDCO]);

    return (
        <div className="doc-type-setting-config">
            <h2>Configuration</h2>

            <div className="config-item first">
                <h4 className="config-item--title">Document Type is Confidential</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.isConfidential}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                isConfidential: !docTypeConfig.isConfidential,
                            })
                        }
                    />
                </div>
            </div>
            <div className="config-item">
                <h4 className="config-item--title">Collect comment while Approving</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.collectComment}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                collectComment: !docTypeConfig.collectComment,
                            })
                        }
                    />
                </div>
            </div>
            <div className="config-item">
                <h4 className="config-item--title">Collect comment while Rejecting</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.collectCommentRejection}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                collectCommentRejection: !docTypeConfig.collectCommentRejection,
                            })
                        }
                    />
                </div>
            </div>
            <div className="config-item">
                <h4 className="config-item--title">Document Type is Contract</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.isContract}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                isContract: !docTypeConfig.isContract,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Restrict Comments Once Published</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.restrictCommentForPublished}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                restrictCommentForPublished: !docTypeConfig.restrictCommentForPublished,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Show Comments After Latest Submission</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.showCommentsAfterSubmitDate}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                showCommentsAfterSubmitDate: !docTypeConfig.showCommentsAfterSubmitDate,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Allow Export</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowExport}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowExport: !docTypeConfig.allowExport,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Allow Template Generation</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowTemplateGeneration}
                        // isDisabled={false}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowTemplateGeneration: !docTypeConfig.allowTemplateGeneration,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Full Width for Title and Description</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.fullWidthTitleDesc}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                fullWidthTitleDesc: !docTypeConfig.fullWidthTitleDesc,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Single Page for Document Creation</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.singlePageDocCreation}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                singlePageDocCreation: !docTypeConfig.singlePageDocCreation,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Is DCO</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.isDCO}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                isDCO: !docTypeConfig.isDCO
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Allow For DCO</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowDCO}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowDCO: !docTypeConfig.allowDCO,
                                allowObsolete: !docTypeConfig.allowDCO,
                                allowRerelease: !docTypeConfig.allowDCO,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Allow Obsolete for document</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowObsolete}
                        isDisabled={docTypeInfo.status === 'PUBLISHED' || docTypeConfig.allowDCO}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowObsolete: !docTypeConfig.allowObsolete,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Allow Rerelease for document</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowRerelease}
                        isDisabled={docTypeInfo.status === 'PUBLISHED' || docTypeConfig.allowDCO}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowRerelease: !docTypeConfig.allowRerelease,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Anyone Can Revise</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowReviseByAnyone}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowReviseByAnyone: !docTypeConfig.allowReviseByAnyone,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Anyone Can View In Process Doc</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowViewingInProcessDocByAnyone}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowViewingInProcessDocByAnyone:
                                    !docTypeConfig.allowViewingInProcessDocByAnyone,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Anyone can edit draft</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.anyoneCanEditDraft}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                anyoneCanEditDraft: !docTypeConfig.anyoneCanEditDraft,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Allow Custom Versioning</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowCustomVersioning}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowCustomVersioning: !docTypeConfig.allowCustomVersioning,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Block Revise</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.blockRevise}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                blockRevise: !docTypeConfig.blockRevise,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Default Initial Version</h4>
                <div className="config-item--section">
                    <div className="version-inputs">
                        <input
                            type="number"
                            id="major"
                            value={docTypeConfig.defaultInitialVersion?.majorVersionNumber}
                            onChange={(e) => versionFieldsHandler(e)}
                        />
                        <div>
                            <p>.</p>
                        </div>
                        <input
                            type="number"
                            id="minor"
                            value={docTypeConfig.defaultInitialVersion?.minorVersionNumber}
                            onChange={(e) => versionFieldsHandler(e)}
                        />
                    </div>
                </div>
            </div>

            <div className="generate-num-outer">
                <label htmlFor="scope-type">Generate Number on</label>
                <div className="select-handler">
                    <input
                        type="radio"
                        id="submit"
                        name="submit"
                        value="submit"
                        checked={generationTrigger.toUpperCase() === 'ON_SUBMIT'}
                        onChange={() => setGenerationTrigger('ON_SUBMIT')}
                        disabled={docTypeInfo.status === 'PUBLISHED' || docTypeConfig.allowDCO}
                    />
                    <label htmlFor="submit">Submit</label>
                    <input
                        type="radio"
                        id="create"
                        name="create"
                        value="create"
                        checked={generationTrigger.toUpperCase() === 'ON_CREATE'}
                        onChange={() => setGenerationTrigger('ON_CREATE')}
                        disabled={docTypeInfo.status === 'PUBLISHED'}
                    />
                    <label htmlFor="create">Create</label>
                </div>
            </div>
            <div className="config-item">
                <h4 className="config-item--title">Enable Sunset Phase</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.isSunsetEnabled}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                isSunsetEnabled: !docTypeConfig.isSunsetEnabled,
                            })
                        }
                    />
                </div>
            </div>
            {docTypeConfig.isSunsetEnabled && (
                <div className="config-item">
                    <h4 className="config-item--title">Max Republish</h4>
                    <div className="config-item--section">
                        <input
                            type="number"
                            className={`${docTypeErrors.maxRepublishCount ? 'error' : ''
                                } effective-period`}
                            onKeyDown={(e) => {
                                if (e.key === '.') e.preventDefault();
                            }}
                            value={Number(docTypeConfig.maxRepublishCount)}
                            disabled={docTypeInfo.status === 'PUBLISHED'}
                            onChange={(e) => {
                                changeMaxRepublishCount(e.target.valueAsNumber);
                            }}
                        />
                    </div>
                </div>
            )}
            {docTypeConfig.isSunsetEnabled && (
                <div className="config-item">
                    <h4 className="config-item--title">EndDate</h4>
                    <div className="config-item--section">
                        <Toggle
                            isChecked={docTypeConfig.isEndDateAsked}
                            isDisabled={docTypeInfo.status === 'PUBLISHED'}
                            onclick={() =>
                                setDocTypeConfig({
                                    ...docTypeConfig,
                                    isEndDateAsked: !docTypeConfig.isEndDateAsked,
                                })
                            }
                        />
                    </div>
                </div>
            )}
            <div className="config-item">
                <h4 className="config-item--title">Publish Once Approved</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.publishOnceApproved}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                publishOnceApproved: !docTypeConfig.publishOnceApproved,
                            })
                        }
                    />
                </div>
            </div>
            {docTypeConfig.isSunsetEnabled && (
                <div className="config-item">
                    <h4 className="config-item--title">Effective Till Date</h4>
                    <div className="config-item--section">
                        <Toggle
                            isChecked={docTypeConfig.isEndDateAsked}
                            isDisabled={docTypeInfo.status === 'PUBLISHED'}
                            onclick={() =>
                                setDocTypeConfig({
                                    ...docTypeConfig,
                                    isEndDateAsked: !docTypeConfig.isEndDateAsked,
                                })
                            }
                        />
                    </div>
                </div>
            )}

            <div className="config-item">
                <h4 className="config-item--title">Enable Approval Deadline</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.enableApprovalDeadline}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                enableApprovalDeadline: !docTypeConfig.enableApprovalDeadline,
                            })
                        }
                    />
                </div>
            </div>
            {docTypeConfig.enableApprovalDeadline && (
                <div className="config-item">
                    <h4 className="config-item--title">Default deadline</h4>
                    <div className="config-item--section">
                        <input
                            type="number"
                            min={1}
                            max={365}
                            onKeyDown={(e) => {
                                if (e.key === '.') e.preventDefault();
                            }}
                            value={docTypeConfig.defaultApprovalDeadline}
                            disabled={docTypeInfo.status === 'PUBLISHED'}
                            onChange={(e) => {
                                changeDefaultApprovalDeadline(e.target.valueAsNumber)
                            }}
                        /> &nbsp; Days
                    </div>
                </div>
            )}
            <div className="config-item">
                <h4 className="config-item--title">Hide Event Timeline</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.hideEventTimeline}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                hideEventTimeline: !docTypeConfig.hideEventTimeline,
                            })
                        }
                    />
                </div>
            </div>
            <div className="config-item">
                <h4 className="config-item--title">Store and Show Activity section</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.storeAndShowActivity}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                storeAndShowActivity: !docTypeConfig.storeAndShowActivity,
                            })
                        }
                    />
                </div>
            </div>
            <div className="config-item">
                <h4 className="config-item--title">Is File Type</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.isFileType}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                isFileType: !docTypeConfig.isFileType,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Allow File Type Attachments</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowFileTypeAttachment}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowFileTypeAttachment: !docTypeConfig.allowFileTypeAttachment,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Document Numbering</h4>
                <div className="config-item--section">
                    <DocTypeNumbering />
                </div>
            </div>

        </div>
    );
};

export default DocTypeAdditionalConfig;
