/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { ChevronDoubleUp, ChevronDoubleDown } from 'react-bootstrap-icons';
import { FilterRow, FilterProps } from '../../interfaces/contracts';
import { DeleteSvg } from '../../assets/images';
import { STATUS_DATA } from '../../components/filters/filter-config';
import './ContractsTableFilters.scss';

interface Props {
    handleReset: () => void;
    setFilterParams: (option: any) => void;
    contractsCount: number;
}

const ContractsTableFilters: React.FC<Props> = ({
    handleReset,
    setFilterParams,
    contractsCount,
}) => {
    const attributeOption = [
        { value: 'documentNumber', label: 'Contract Number' },
        { value: 'documentNumber', label: 'Contract Type' },
        { value: 'employeeName', label: 'Owner' },
        { value: 'state', label: 'Status' },
        { value: 'updatedAt', label: 'Published On' },
        { value: 'partyName', label: 'Party Name' },
        { value: 'contractType', label: 'Category' },
    ];
    const operatorOption = [
        [
            { value: 'contain', label: 'Contains' },
            { value: 'notContain', label: 'Does Not Contain' },
            { value: 'eq', label: 'Is Equal To' },
            { value: 'neq', label: 'Is Not Equal To' },
            { value: 'startsWith', label: 'Starts With' },
            { value: 'notStartWith', label: 'Does Not Start With' },
        ],
        [
            { value: 'dateeq', label: 'Is Equal To' },
            { value: 'isBefore', label: 'Is Before' },
            { value: 'isAfter', label: 'Is After' },
            { value: 'isBetween', label: 'Is Between' },
            { value: 'isToday', label: 'Is Today' },
            { value: 'isWeek', label: 'Last 7 Days' },
            { value: 'isMonth', label: 'Last 30 Days' },
            { value: 'isTwoMonth', label: 'Last 60 Days' },
            { value: 'isSixMonth', label: 'Last 6 Months' },
            { value: 'isTwelveMonths', label: 'Last 12 Months' },
        ],
    ];
    const statusOption = STATUS_DATA.slice(1);
    const dateOperatorArray = [
        'isToday',
        'isWeek',
        'isMonth',
        'isTwoMonth',
        'isSixMonth',
        'isTwelveMonths',
    ];
    const defaultFilterRow = {
        id: 1,
        attribute: { value: '', label: 'Select...' },
        operator: { value: '', label: 'Select...' },
        value: '',
        isStatusFilter: false,
        status: { value: '', label: 'Select...' },
        isDateFilter: false,
        date: {
            dateValue: null,
            dateStartValue: null,
            dateEndValue: null
        }
    };
    const [filterRows, setFilterRows] = useState<FilterRow[]>([defaultFilterRow]);
    const [showFilters, setShowFilters] = useState(true);
    const [searchedFilters, setSearchedFilters] = useState<FilterRow[]>([]);

    const handleAttributeChange = (option: any, rowNum: number) => {
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                const isDateFilter = option.value === 'updatedAt';
                const isStatusFilter = option.value === 'state';
                if (isDateFilter) {
                    return {
                        ...row,
                        isDateFilter,
                        attribute: option,
                        operator: { value: '', label: 'Select...' },
                        value: '',
                        date: {
                            dateValue: new Date(),
                            dateStartValue: null,
                            dateEndValue: null
                        }
                    }
                }
                if (isStatusFilter) {
                    return {
                        ...row,
                        isStatusFilter,
                        isDateFilter,
                        attribute: option,
                        operator: { value: 'eq', label: 'Is Equal To' },
                        value: ''
                    }
                }
                return {
                    ...row,
                    isStatusFilter,
                    isDateFilter,
                    attribute: option,
                    operator: { value: '', label: 'Select...' },
                    value: ''
                }
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleOperatorChange = (option: any, rowNum: number) => {
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                if (row.isDateFilter && (option.value === 'isBetween' || dateOperatorArray.includes(option.value))) {
                    return { ...row, operator: option, date: { dateValue: null, dateStartValue: null, dateEndValue: null } };
                }
                if (row.isDateFilter && !dateOperatorArray.includes(option.value)) {
                    return { ...row, operator: option, date: { dateValue: new Date(), dateStartValue: null, dateEndValue: null } };
                }
                return { ...row, operator: option };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleValueChange = (e: React.FormEvent<HTMLInputElement>, rowNum: number) => {
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                return { ...row, value: e.currentTarget.value };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleStatusChange = (option: any, rowNum: number) => {
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                return { ...row, status: option, value: option.value };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleDateChange = (chosenDate: Date | null, rowNum: number) => {
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                return { ...row, date: { dateValue: chosenDate, dateStartValue: null, dateEndValue: null } };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleStartDateChange = (chosenDate: Date | null, rowNum: number) => {
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                const dateObj = { ...row.date, dateValue: null, dateStartValue: chosenDate }
                return { ...row, date: dateObj };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleEndDateChange = (chosenDate: Date | null, rowNum: number) => {
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                const dateObj = { ...row.date, dateValue: null, dateEndValue: chosenDate }
                return { ...row, date: dateObj };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleAddFilter = () => {
        if (filterRows.length < 7) {
            const newRow = { ...defaultFilterRow, id: filterRows[filterRows.length - 1].id + 1 }
            setFilterRows([...filterRows, newRow]);
        }
    }

    const handleDeleteFilter = (rowNum: number) => {
        setFilterRows(filterRows.filter((fr) => fr.id !== rowNum))
    }

    const formatDate = (date: Date | null) => {
        const dateValue = date && typeof date.getMonth === 'function'
            ? date.getTime() / 1000
            : null;
        return dateValue;
    };

    const searchHandler = () => {
        const paramArr: { attribute: string; operator: string; attributeValue: string; dateValue: number | null; dateStartValue: number | null; dateEndValue: number | null; }[] = [];
        let isValidParams = true;
        filterRows.forEach(row => {
            const { attribute, operator, value, isStatusFilter, status, isDateFilter, date: { dateValue, dateStartValue, dateEndValue } } = row;
            if (!isDateFilter && !isStatusFilter) {
                if (attribute.value === '' || operator.value === '' || value === '')
                    isValidParams = false;
            }
            if (isStatusFilter) {
                if (status.value === '')
                    isValidParams = false;
            }
            if (isDateFilter) {
                if (operator.value === '') {
                    isValidParams = false;
                } else if (operator.value === 'isBetween' && (dateStartValue === null || dateEndValue === null)) {
                    isValidParams = false;
                } else if (operator.value !== 'isBetween' && !dateOperatorArray.includes(operator.value) && dateValue === null) {
                    isValidParams = false;
                }
            }
            const paramObj = {
                attribute: attribute?.value || '',
                operator: operator?.value || '',
                attributeValue: value || '',
                dateValue: formatDate(dateValue) || null,
                dateStartValue: formatDate(dateStartValue) || null,
                dateEndValue: formatDate(dateEndValue) || null
            }
            paramArr.push(paramObj);
        });

        if (isValidParams) {
            setShowFilters(false);
            setFilterParams(paramArr);
            setSearchedFilters(filterRows);
        } else {
            toast.error('Enter all filter values');
        }
    };

    const resetHandler = () => {
        setFilterRows([defaultFilterRow]);
        setSearchedFilters([]);
        handleReset();
    };

    // eslint-disable-next-line max-lines-per-function
    const FiltersRow: React.FC<FilterProps> = ({ row }) => {
        const { id, attribute, operator, value, isStatusFilter, status, isDateFilter, date: { dateValue, dateStartValue, dateEndValue } } = row;
        return (
            <div className="filter-row" id={id.toString()}>
                <div className="filter filter-right-margin">
                    <label htmlFor={`attributeFilter_${id}`}>Attribute</label>
                    <Select
                        name={`attributeFilter_${id}`}
                        id={`attributeFilter_${id}`}
                        className="react-select"
                        classNamePrefix="select"
                        options={attributeOption}
                        onChange={e => handleAttributeChange(e, id)}
                        value={attribute}
                    />
                </div>
                {!isStatusFilter && (
                    <div className="filter filter-right-margin">
                        <label htmlFor={`operatorFilter_${id}`}>Operator</label>
                        <Select
                            name={`operatorFilter_${id}`}
                            id={`operatorFilter_${id}`}
                            className="react-select"
                            classNamePrefix="select"
                            options={isDateFilter ? operatorOption[1] : operatorOption[0]}
                            onChange={e => handleOperatorChange(e, id)}
                            value={operator}
                        />
                    </div>
                )}
                {!isDateFilter && !isStatusFilter && (
                    <div className="filter filter-right-margin">
                        <label htmlFor={`filterValue_${id}`}>Value</label>
                        <input
                            name="filterValue"
                            id={`filterValue_${id}`}
                            key={`filterValue_${id}`}
                            defaultValue={value}
                            onBlur={e => handleValueChange(e, id)}
                            placeholder="Enter Value"
                            className="input-value"
                        />
                    </div>
                )}
                {isStatusFilter && (
                    <div className="filter filter-right-margin">
                        <label htmlFor={`statusFilter_${id}`}>Value</label>
                        <Select
                            name={`statusFilter_${id}`}
                            id={`statusFilter_${id}`}
                            className="react-select"
                            classNamePrefix="select"
                            options={statusOption}
                            onChange={e => handleStatusChange(e, id)}
                            value={status}
                        />
                    </div>
                )}
                {isDateFilter && !(operator.value === 'isBetween') && !(dateOperatorArray.includes(operator.value)) && (
                    <div className="filter filter-right-margin">
                        <label htmlFor={`filterDateValue_${id}`}>Value</label>
                        <DatePicker
                            name="filterDateValue"
                            id={`filterDateValue_${id}`}
                            selected={dateValue}
                            onChange={(date: Date | null) => handleDateChange(date, id)}
                            className="input-value"
                        />
                    </div>
                )}
                {isDateFilter && (operator.value === 'isBetween') && (
                    <>
                        <div className="filter">
                            <label htmlFor={`filterStartDateValue_${id}`}>Value</label>
                            <DatePicker
                                name="filterStartDateValue"
                                id={`filterStartDateValue_${id}`}
                                selected={dateStartValue}
                                onChange={(date: Date | null) => handleStartDateChange(date, id)}
                                className="input-value"
                                placeholderText="Start Date"
                            />
                        </div>
                        <div className="filter-dash-margin">&nbsp;-&nbsp;</div>
                        <div className="filter filter-margin filter-right-margin">
                            <DatePicker
                                name="filterEndDateValue"
                                id={`filterEndDateValue_${id}`}
                                selected={dateEndValue}
                                onChange={(date: Date | null) => handleEndDateChange(date, id)}
                                className="input-value"
                                placeholderText="End Date"
                            />
                        </div>
                    </>
                )}
                <div className="delete-filter">
                    {filterRows.length > 1 &&
                        <DeleteSvg
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteFilter(id)}
                        />
                    }
                </div>
            </div>
        )
    };

    return (
        <div className="contract-table-filters">
            {showFilters && (
                <>
                    {filterRows.map((filterRow) => (
                        <FiltersRow key={filterRow.id} row={filterRow} />
                    ))}
                    <div className="filter-buttons">
                        <div className="button-div filter-right-margin">
                            <button type="button" onClick={handleAddFilter} disabled={filterRows.length > 6}>
                                Add Filter
                            </button>
                        </div>

                        <div className="button-div filter-right-margin">
                            <button type="button" onClick={searchHandler}>
                                Search
                            </button>
                        </div>
                        <div className="button-div">
                            <button type="button" onClick={resetHandler}>
                                Reset
                            </button>
                        </div>
                    </div>
                </>
            )}

            {!showFilters && !!searchedFilters.length && (
                <div className="filter-summary">
                    <h4>Searched for: </h4>
                    {searchedFilters.map((row, i) => {
                        const dateString = row?.date?.dateStartValue ?
                            `${row?.date?.dateStartValue?.toDateString()} - ${row?.date?.dateEndValue?.toDateString()}`
                            :
                            `${row?.date?.dateValue?.toDateString() || ''}`;
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <span key={i}>
                                {row.isDateFilter ? (
                                    ` ${row?.attribute?.label} ${row?.operator?.label?.toLowerCase()} ${dateString} `
                                ) : (
                                    ` ${row?.attribute?.label} ${row?.operator?.label?.toLowerCase()} '${row?.value}' `
                                )}
                            </span>
                        )
                    })}
                </div>
            )}
            <h4 className="contracts-count"> Total Contracts: <span>{contractsCount}</span></h4>
            <div className="filter-arrow" title="Click to show / hide filters">
                {showFilters ? (
                    <ChevronDoubleUp onClick={() => setShowFilters(false)} />
                ) : (
                    <ChevronDoubleDown onClick={() => setShowFilters(true)} />
                )}
            </div>
        </div>
    );
};

export default ContractsTableFilters;
