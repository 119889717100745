/* eslint-disable max-lines-per-function */
import React from 'react';
import Modal from 'react-modal';
import DocumentInfo from '../../widgets/document-info/DocumentInfo';
import { useDocumentContext } from '../../contexts/document-provider';

import './DocumentPreview.scss';
import ImportantFields from '../important-fields/ImportantFields';
import ViewAttachments from '../attachments/view/ViewAttachments';
import { StageCard } from '../work-flow-panel/WorkFlowPanel';
import Reviewers from '../reviewers/Reviewers';
import { EditSvg, PlusIconSvg } from '../../assets/images';
import { IDocChecklistSteps } from '../../interfaces/document';
import Button from '../button/Button';
import STATIC_CONTENT from '../../constants/StaticContent';

const { DOCUMENT_PREVIEW } = STATIC_CONTENT;
interface Props {
    openModal: boolean;
    onModalClose: () => void;
    onEdit: (step: IDocChecklistSteps) => void;
}

const DocumentPreview: React.FC<Props> = ({ openModal, onModalClose, onEdit }) => {
    const {
        docInfo,
        fields,
        fieldsData,
        documentId,
        selectedDocVersion,
        activeDocVersionId,
        docVersions,
        approvers,
        reviewers,
        docTypeInfo,
        attachmentConfig,
        setSelectedDocVersion,
        setDocInfo,
        fetchDocumentVersion,
        publishOnceApproved,
        isSunsetEnabled,
        activeVersionOwnerInfo,
        activeVersionApprovalDeadline,
        setActiveVersionApprovalDeadline,
        isEndDateAsked,
        relationArr,
        setRelationArr,
        latestrelationArr,
        setlatestrelationArr,
        approversDispatch,
        saveFieldsData,
        checklist
    } = useDocumentContext();
    return (
        <Modal
            isOpen={openModal}
            onRequestClose={() => onModalClose()}
            contentLabel="Example Modal"
            className="document-preview-modal"
        >
            <div className="preview">
                <PlusIconSvg className="close-btn" onClick={() => onModalClose()} />
                <h1 className="heading">{DOCUMENT_PREVIEW.HEADING}</h1>
                <DocumentInfo
                    pageType="DOCUMENT_PREVIEW"
                    docInfo={docInfo}
                    documentId={documentId}
                    activeDocVersion={selectedDocVersion}
                    latestDocVersionId={activeDocVersionId}
                    docVersions={docVersions}
                    setActiveDocVersion={setSelectedDocVersion}
                    setDocInfo={setDocInfo}
                    onFavoriteClickHAndler={fetchDocumentVersion}
                    descError={false}
                    titleError={false}
                    effectiveFromError={false}
                    effectivePeriodError={false}
                    sunsetPeriodError={false}
                    docTypeInfo={docTypeInfo}
                    publishOnceApproved={publishOnceApproved}
                    isSunsetEnabled={isSunsetEnabled}
                    activeVersionOwnerInfo={activeVersionOwnerInfo}
                    activeVersionApprovalDeadline={activeVersionApprovalDeadline}
                    setActiveVersionApprovalDeadline={setActiveVersionApprovalDeadline}
                    isEndDateAsked={isEndDateAsked}
                    relationArr = {relationArr}
                    setRelationArr = {setRelationArr}
                    latestrelationArr = {latestrelationArr}
                    setlatestrelationArr = {setlatestrelationArr}
                />
                <div className="main-section" id="docDetailMain">
                    <div className="document-details">
                        <h3>{DOCUMENT_PREVIEW.DOCUMENT_DETAILS}</h3>
                        <EditSvg className="edit-icon" onClick={() => onEdit('STEP1')} />
                        <ImportantFields
                            isDisabled
                            fields={fields}
                            fieldsData={fieldsData}
                            documentDispatch={null}
                        />
                    </div>
                    <div className="attachments">
                        <EditSvg className="edit-icon" onClick={() => onEdit('STEP2')} />
                        {selectedDocVersion.value && (
                            <ViewAttachments
                                activeDocVersion={selectedDocVersion.value as number}
                                attachmentConfig={attachmentConfig}
                                showTitle
                                isDocFetched
                                allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                                checklist = {checklist}
                            />
                        )}
                    </div>

                    {approvers.length > 0 && (
                        <div className="approvers">
                            <h3>Approvers</h3>
                            <EditSvg className="edit-icon" onClick={() => onEdit('STEP3')} />
                            <div className="approvers-tab tab-data">
                                {approvers.map((approver, index) => (
                                    <>
                                        {approver.approvers.length > 0 ? (
                                            <StageCard
                                                stageIndex={index}
                                                error={false}
                                                stageData={approver}
                                                approversDispatch={approversDispatch}
                                                setActiveVersionApprovalDeadline={setActiveVersionApprovalDeadline}
                                                isEditable={false}
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={index}
                                                cardTitle={approver.approvalStageTitle ? approver.approvalStageTitle : 'Select Approvers'}
                                                docTypeInfo={docTypeInfo}
                                                docInfo={docInfo}
                                                activeVersionOwnerInfo={activeVersionOwnerInfo}
                                                saveFieldsData={saveFieldsData}
                                            />
                                        ) : (
                                            <p className="no-approvers">
                                                Stage-{index+1}: {DOCUMENT_PREVIEW.NO_APPROVERS}
                                            </p>
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    )}
                    {reviewers.length > 0 && (
                        <div className="reviewers">
                            <h3>{DOCUMENT_PREVIEW.REVIEWERS}</h3>
                            <Reviewers isEditable={false} />
                        </div>
                    )}
                </div>
                <div className="buttons">
                    <Button
                        type="button"
                        className="primary-btn"
                        disabled={false}
                        isLoading={false}
                        onClick={() => onModalClose()}
                    >
                        {DOCUMENT_PREVIEW.BUTTON.CLOSE}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default DocumentPreview;
