/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './TravelForm.scss'; // Make sure to create this file or remove this line if not needed
import { Button } from 'react-bootstrap'; // Make sure to install react-bootstrap
import { ITravelForm } from '../../../interfaces';
import { IField } from '../../../interfaces/document';
import { IDocumentAction } from '../../../reducers/document-reducer';
import { RemoveIconSvg } from '../../../assets/images';

interface Props {
    field: IField;
    isDisabled: boolean;
    value: ITravelForm[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

const initialTravelForm: ITravelForm = { "from" : '', "to": '', "fromDate": '', "toDate": '' };

// eslint-disable-next-line max-lines-per-function
const TravelForm = ({ field, isDisabled, value, documentDispatch, error }: Props) => {
    const [data, setData] = useState<ITravelForm[]>(value);

    const handleAddRow = () => {
        const addedRowdata = [...data, { ...initialTravelForm }];
        setData(addedRowdata);
        if(documentDispatch){
          documentDispatch({
            type : 'travel-form',
            inputName : field.name,
            value : addedRowdata,
          })
        }
    };

    const handleDeleteRow = (index: number) => {
        const deletedRowData = data.filter((item, i) => i !== index);
        setData(deletedRowData);
        if(documentDispatch){
          documentDispatch({
            type : 'travel-form',
            inputName : field.name,
            value : deletedRowData,
          })
        }
    };

    const handleChange = (index: number, fieldName: string, info: string) => {
        const updatedData = data.map((row, i) =>
            i === index ? { ...row, [fieldName]: info } : row,
        );
        setData(updatedData);
        if(documentDispatch){
          documentDispatch({
            type : 'travel-form',
            inputName : field.name,
            value : updatedData,
          })
        }
    };

    useEffect(() => {
        setData(value);
    }, [value]);

    return (
        <div className={`styled-container ${error ? 'error' : ''}`}>
            <div className='table-wrapper'>
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>From</th>
                            <th>To</th>
                            <th>From Date</th>
                            <th>To Date</th>
                            {!isDisabled ? <th>Actions</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr>
                                <td>
                                    <input
                                        className='styled-input'
                                        disabled={isDisabled}
                                        type="text"
                                        value={row.from}
                                        onChange={(e) => handleChange(index, 'from', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        className='styled-input'
                                        disabled={isDisabled}
                                        type="text"
                                        value={row.to}
                                        onChange={(e) => handleChange(index, 'to', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        disabled={isDisabled}
                                        className="styled-date-picker"
                                        type="date"
                                        placeholder='dd/mm/yyyy'
                                        value={row.fromDate}
                                        onChange={(e) =>
                                            handleChange(index, 'fromDate', e.target.value)
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        disabled={isDisabled}
                                        className="styled-date-picker"
                                        type="date"
                                        value={row.toDate}
                                        onChange={(e) => handleChange(index, 'toDate', e.target.value)}
                                    />
                                </td>
                                {!isDisabled ? <td>
                                    <span
                                        aria-hidden="true"
                                        style={{ cursor: 'pointer' }}
                                        data-tip
                                        data-for="remove-action"
                                        className="action-icon"
                                        onClick={() => handleDeleteRow(index)}
                                    >
                                        <RemoveIconSvg />
                                    </span>
                                </td> : null}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {!isDisabled && data.length < 5 ? <Button className="styled-button" onClick={handleAddRow}>
                Add Row
            </Button> : null}
        </div>
    );
};

export default TravelForm;
